import { useState, useEffect } from "react";
export default function Counter({ countEnd }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === countEnd) {
          clearInterval(intervalId);
          return prevCount;
        }
        return prevCount + 1;
      });
    }, 1);
    return () => clearInterval(intervalId);
  }, [countEnd]);
  return count;
}
