import React from "react";
import ImageGallery1 from "./Gallery/Mask group.png";
import ImageGallery2 from "./Gallery/Mask group2.png";
import ImageGallery3 from "./Gallery/Mask group3.png";

export default function Gallery() {
  return (
    <div className=" md:pt-44 pt-10 max-width-[100%] lg:mt-28 xl:pt-[100px] 2xl:pt-[150px] 3xl:pt-[180px]">
      <div id="GALLERY">
        <p className="text-[#E73338] flex  justify-center 2xl:-mt-20 font-bold text-[14px] md:text-base lg:text-[18px] xl:text-xl 2xl:text[22px] 3xl:text-[24px] 2xl:leading-[3rem] text-center">
          Features Gallery
        </p>
        <p className="flex items-center justify-center font-extrabold text-2xl pt-5  md:text-[36px] lg:text-[42px] xl:text-[48px] 2xl:text-[50px] 3xl:text-[52px] 2xl:leading-[3.5rem]  3xl:leading-[4rem] lg:leading-[3rem] text-center">
          Check how our <br /> process is done
        </p>
        <div className="flex items-center justify-center w-full ">
          <p className="text-[#5F5C5F] flex items-center justify-center pt-5 px-2 md:pl-0 md:max-w-[405px] md:text-[12px] lg:max-w-[470px] lg:text-[14px] xl:text-base xl:max-w-[38%] 2xl:max-w-[625px]  2xl:text-[18px] 3xl:max-w-[750px] 3xl:text-[22px] text-center font-light">
            This means regular service at the right time by knowledgeable
            people, and using parts that were specifically designed for your
            equipment.
          </p>
        </div>
      </div>

      <div className="flex items-center pt-20 mx-auto pb-20 gap-1 md:gap-5 overflow-x-auto w-full no-scrollbar">
        <img
          src={ImageGallery3}
          alt="Image"
          className="object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery1}
          alt="Image"
          className="object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />

        <img
          src={ImageGallery2}
          alt="Image"
          className=" object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery3}
          alt="Image"
          className="object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery2}
          alt="Image"
          className=" object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery1}
          alt="Image"
          className="object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery3}
          alt="Image"
          className="object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery2}
          alt="Image"
          className=" object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
        <img
          src={ImageGallery1}
          alt="Image"
          className="object-cover w-[100px] md:w-[150px] lg:w-[382px] lg:h-[369px] 3xl:w-[500px] "
        />
      </div>

      <div className="bg-[#0082C0] text-white md:flex md:justify-between pt-5 pb-5 md:py-5 md:px-20 px-[30px] xl:py-[61px] lg:w-full xl:px-[215px] 3xl:px-[325px] w-full">
        <p className="xl:text-[24px] xl:leading-[28.8px] font-light text-xs md:text-base md:max-w-[65%] xl:w-[565px] 2xl:w-full 3xl:text-3xl ">
          The complete range of highly efficient Products Contact Our Product
          Specialists
        </p>
        <button className="bg-[#E73338]  text-white py-2 px-3 md:py-1.5 md:px-5 rounded-full text-xs lg:text-sm text-nowrap ml-[calc(100%-136px)] md:ml-0 self-end xl:text-base 3xl:text-xl">
          REQUEST A QUOTE
        </button>
      </div>
    </div>
  );
}
