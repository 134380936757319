import { Link } from "react-scroll";
import Arrow from "./Icons/Arrow";
import ShaffafLogo from "./images/ShaffafLogo";
import MenuNavBar from "./images/MenuBar";
export default function Navbar() {
  return (
    <>
      <nav className="md:flex md:items-center md:justify-between md:h-20 h-0 lg:px-20 lg:py-8 py-2.5 md:px-2.5 overflow-y-hidden invisible md:visible xl:mt-5 ">
        <div className="md:flex items-center justify-center lg:flex lg:overflow-x-hidden md:pl-44 lg:pl-32 md:pr-4 md:gap-x-[10px] lg:gap-4 xl:gap-10 2xl:gap-12 lg:text-base xl:w-[90%]    ">
          <span className="xl:pr-8">
            <shaffaf />
          </span>
          <a className="link-text hover:text-[#0082C0]" id="HOME" href="#">
            HOME
          </a>
          <Link
            to="ABOUT US"
            smooth={true}
            duration={500}
            className="text-nowrap link-text hover:text-[#0082C0]"
            href="#"
          >
            ABOUT US
          </Link>
          <Link
            className="link-text hover:text-[#0082C0]"
            to="SERVICES"
            smooth={true}
            duration={500}
            href="#"
          >
            SERVICES
          </Link>
          <Link
            className="link-text hover:text-[#0082C0]"
            to="PRODUCTS"
            smooth={true}
            duration={500}
            href="#"
          >
            PRODUCTS
          </Link>
          <Link
            className="link-text hover:text-[#0082C0]"
            to="GALLERY"
            smooth={true}
            duration={500}
            href="#"
          >
            GALLERY
          </Link>
          <a className="link-text hover:text-[#0082C0]" href="#">
            DOWNLOAD
          </a>
        </div>
        <Link
          to="Contact-us"
          smooth={true}
          duration={500}
          className=" bg-[#E73338] text-white lg:px-6 lg:py-3 rounded-full text-nowrap flex font-base items-center gap-2.5 md:px-3 md:py-1.5 md:text-sm"
        >
          CONTACT US
          <Arrow />
        </Link>
      </nav>
      <div>
        <MenuNavBar />
      </div>
    </>
  );
}
