import { clear } from "@testing-library/user-event/dist/clear";
import Counter from "./Counter";
import fetchedImgSrc from "./images/BackGroundImage.png";
import React from "react";
import Phone from "./Icons/Phone";
import Mail from "./Icons/Mail";
import Hour from "./Icons/Hour";
import Arrow from "./Icons/Arrow";
import PlusIcon from "./Icons/Plus";
import PhoneNumber from "./PhoneNumber";

export default function HOME_PAGE() {
  return (
    <>
      <div id="HOME" className="w-full relative">
        <div className="relative z-0 mt-2 md:mt-0">
          <img
            src={fetchedImgSrc}
            alt="BackGround-Image"
            className="absolute -z-10 w-full h-full object-cover"
          />
          <div className="tayyab bg-[#0E181E]/95 inset-0 absolute -z-10 mix-blend-hard-light " />
          <div className="z-10 font-primaryRegular mx-container">
            <div className="text-2xl ">
              <div className="text-white lg:text-6xl lg:pl-0 md:text-4xl font-bold lg:py-20 md:py-16 3xl:text-9xl pt-3 md:pt-0  ">
                <div>
                  <p className=" mt-2 text-[40px] lg:pt-0  md:text-current xl:text-6xl md:mt-0 pt-10 md:ml-0 2xl:text-7xl leading-[46px] 3xl:text-8xl ">
                    The future <p>of energy efficient,</p>
                    <p className="mt-1">low cost cooling</p>
                  </p>
                </div>
                <div className="flex flex-col pb-36 xl:gap-28 lg:flex-col xl:items-center lg:gap-16 xl:flex-row md:gap-y-10 2xl:w-96 ">
                  <div className="text-white text-[16px] leading-[19.2px] font-light pt-20 md:flex md:items-start grid grid-cols-2 md:justify-start md:gap-10 lg:gap-20 gap-y-3 3xl:text-3xl 3xl:gap-32  3xl:max-w-[1160px] 2xl:mt-12">
                    <div>
                      <p>
                        AWARDS <br /> WINNING <br />
                        <div className="mt-2 xl:w-24">
                          <span className="relative font-bold text-5xl  3xl:text-[88px] ">
                            {<Counter countEnd={112} />}
                            <span className="absolute 3xl:-right-8 3xl:top-5 top-3">
                              <PlusIcon />
                            </span>
                          </span>
                        </div>
                      </p>
                    </div>

                    <div className="">
                      <p>
                        SATISFIED <br /> CUSTOMERS <br />
                        <div className="mt-2 xl:w-24">
                          <span className="relative font-bold text-5xl 3xl:text-[88px] ">
                            <Counter countEnd={359} />
                            <span className="absolute 3xl:-right-8 3xl:top-5 top-3">
                              <PlusIcon />
                            </span>
                          </span>
                        </div>
                      </p>
                    </div>

                    <p>
                      PROFESSIONAL <br /> STAFF <br />
                      <div className="mt-2 xl:w-24">
                        <span className="relative font-bold text-5xl 3xl:text-[88px] ">
                          <Counter countEnd={265} />
                          <span className="absolute 3xl:-right-8  3xl:top-5 top-3">
                            <PlusIcon />
                          </span>
                        </span>
                      </div>
                    </p>
                    <p>
                      COMPLETED <br /> PROJECTS <br />
                      <div className="mt-2 xl:w-32">
                        <span className="relative font-bold text-5xl 3xl:text-[88px]">
                          <Counter countEnd={1500} />
                          <span className="absolute 3xl:-right-8  3xl:top-5 top-3">
                            <PlusIcon />
                          </span>
                        </span>
                      </div>
                    </p>
                  </div>
                  <div className="pt-10 md:pt-0 space-y-6 3xl:ml-60 ">
                    <p className="w-[290px] md:w-[60%] lg:w-[51%] text-base font-light   xl:w-[312px] 3xl:w-[540px] 3xl:text-3xl 3xl:leading-[2.5rem] 2xl:text-xl ">
                      Shaffaf was established in 2005 in Pakistan. They have
                      staff those who have more than 40 years’ experience and
                      specialist on compressor Parts.
                    </p>
                    <div className="flex gap-3.5 2xl:flex">
                      <button className=" bg-[#E73338] text-white px-4 lg:px-6 rounded-full text-nowrap flex md:font-light text-xs items-center gap-3 md:px-3 md:py-1.5 md:text-sm 3xl:text-xl 3xl:px-8 3xl:py-4 2xl:text-lg leading-0 2xl:px-6 2xl:py-3 ">
                        READ MORE
                        <Arrow />
                      </button>
                      <button className="border bg-transparent text-white rounded-full px-4 py-2 md:px-6 md:py-3 font-light text-xs text-nowrap 3xl:text-xl 3xl:px-8 3xl:py-4 2xl:text-lg leading-0 ">
                        CONTACT US
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="bg-[#0E181E] -mt-20 md:mt-0 blur-none text-white md:-translate-y-1/2 md:px-24 md:py-11  lg:px-0  w-full md:max-w-[calc(100%-((100%-768px)/2)-40px)] lg:max-w-[calc(100%-((100%-1024px)/2)-40px)] xl:max-w-[calc(100%-((100%-1280px)/2)-56px)] 2xl:max-w-[calc(100%-((100%-1536px)/2)-64px)] 3xl:max-w-[calc(100%-((100%-2160px)/2)-80px)] ml-7 md:ml-10 float-right">
          <div
            className="flex-col md:flex-row md:justify-between lg:justify-center  gap-10 pt-3 pb-3 md:pt-0 md:pb-0 md:flex md:space-x-5 lg:gap-40 xl:gap-28 2xl:gap-52 2xl:pl-0 ml-7 xl:-ml-20 xl:space-x-10 2xl:ml-0 2xl:space-x-0 

            md:mr-10 lg:mr-0"
          >
            <div className="space-y-4">
              <Phone />
              <div className="space-y-2 xl:text-[24px]">
                <p className="text-nowrap 3xl:text-[30px]  ">Phone Number</p>
                <p className="text-nowrap  text-[#9D9D9D] xl:text-[20px] 3xl:text-[30px]">
                  041-35411331
                </p>
              </div>
            </div>
            <div className="space-y-4 mt-3 md:mt-0 lg:ml-10">
              <Mail />
              <div className="space-y-2 xl:text-[24px]">
                <p className=" text-nowrap 3xl:text-[30px] ">Email Address</p>
                <p className="text-nowrap text-[#9D9D9D] xl:text-[20px] 3xl:text-[30px]">
                  info@shaffaf.org
                </p>
              </div>
            </div>
            <div className="space-y-4 mt-3 md:mt-0 lg:mr-[100px] xl:mr-0 xl:ml-10 2xl:ml-0">
              <Hour />
              <div className="space-y-2 ">
                <p className=" text-nowrap xl:text-[24px] 3xl:text-[30px] ">
                  Business Hours
                </p>
                <p className=" text-nowrap text-[#9D9D9D]  xl:text-[20px] 3xl:text-[30px]  ">
                  Monday - Friday 9AM - 5PM
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
