import React from "react";

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        fill="#fff"
        d="M34.672.147H2.328C1.55.147.922.776.922 1.554v32.343c0 .778.628 1.407 1.406 1.407h32.344c.778 0 1.406-.629 1.406-1.407V1.554c0-.778-.628-1.407-1.406-1.407zM11.35 30.105H6.134V13.327h5.216v16.778zM8.744 11.033a3.024 3.024 0 110-6.047 3.024 3.024 0 010 6.047zM30.88 30.105h-5.212v-8.16c0-1.947-.035-4.448-2.71-4.448-2.712 0-3.13 2.118-3.13 4.307v8.301H14.62V13.327h5v2.294h.07c.695-1.319 2.396-2.712 4.936-2.712 5.282 0 6.253 3.476 6.253 7.994v9.202z"
      ></path>
    </svg>
  );
}

export default Linkedin;
