import ProductCarousel from "./ProductCarousal";

export default function Products() {
  return (
    <div className="mx-container">
      <div
        id="PRODUCTS"
        className="flex flex-col md:flex-row  pt-16 md:pt-28 mx-auto justify-between gap-y-3"
      >
        <div className="space-y-2 md:space-y-0 ">
          <p className="text-[#E73338] font-bold  text-xl 3xl:text-2xl">
            Our Products
          </p>
          <p className="text-black font-bold text-2xl xl:text-5xl lg:text-4xl lg:leading-[3rem] md:text-3xl max-w-[316px] md:max-w-[416px] md:pt-5   3xl:max-w-[600px] 3xl:text-6xl 3xl:leading-[4.5rem] xl:leading-[57.6px]">
            Here are some of our products.
          </p>
        </div>
        <p className="text-[#5F5C5F] font-light md:mt-14 text-left lg:text-[14px] lg:leading-[20px] md:px-10  md:text-xs 3xl:text-xl text-xs 2xl:text-[18px] 2xl:leading-[22px] md:w-[calc(100%-8rem)] lg:w-[calc(100%-30rem)] xl:w-[calc(100%-44rem)] 2xl:w-[calc(100%-50rem)] 3xl:w-[calc(100%-80rem)] 4xl:w-[calc(100%-70rem)]">
          A long compressor lifetime, build on reliability and cost-saving?
          Regular service at the right time by knowledge people and the right
          original parts. Leave that to us. Great maintenance extends the
          lifetime of your compressor and makes it work more reliably and
          cost-effectively.
        </p>
      </div>
      <ProductCarousel />
    </div>
  );
}
