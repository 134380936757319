import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="35"
      fill="none"
      viewBox="0 0 19 35"
    >
      <path
        fill="#fff"
        d="M12.12 34.595V19.228h5.184l.77-6.017H12.12V9.378c0-1.736.484-2.925 2.976-2.925h3.158V1.09A41.88 41.88 0 0013.627.85c-4.583 0-7.729 2.797-7.729 7.933v4.415H.748v6.017h5.161v15.379h6.21z"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
