import OriginalImage from "./images/ServicesLogo/Original.svg";
import OilSeparatorImage from "./images/ServicesLogo/OilSeparator.svg";
import LubricantImage from "./images/ServicesLogo/Lubricant.svg";
import AirQualityImage from "./images/ServicesLogo/AirQuality.svg";
import PathArrow from "./Icons/PathArrow";
import ApuredaLogo from "./images/ServicesLogo/Apureda";
import ElangLogo from "./images/ServicesLogo/ElangLogo";
import SunwnLogo from "./images/ServicesLogo/SunwnLogo";
import Sun1 from "./images/ServicesLogo/sun1.png";
const services = [
  {
    id: "1",
    title: "Why original parts matter",
    description:
      "When you purchase a compressor of a trusted brand, it has original parts built in that ensure the energy efficiency, reliability and performance you expect. So when the time comes to service your compres...",
    logo: OriginalImage,
  },
  {
    id: "2",
    title: "Why use original Oil Separators",
    description:
      "As it is not built for the working conditions and pressures inside your compressor, it may be subject to faster wear and shorter service life. Excessive flow resistance may cause pressure drops, resultin... ",
    logo: OilSeparatorImage,
  },
  {
    id: "3",
    title: "The Right Lubricant",
    description:
      "Lubricants are essential for the operation of air compressors. For the perfect functioning of your machines you should choose the right lubricant. Shaffaf lubricants will ensure optimized performance of...      ",
    logo: LubricantImage,
  },
  {
    id: "4",
    title: "Working with top air quality    ",
    description:
      "Dust and other contaminants, however small, are a threat to your compressed air system. Filters have the delicate role of preventing these particles from entering the air and oil circuit without causing ...      ",
    logo: AirQualityImage,
  },
  {
    id: "5",
    title: "Why use original Oil Separators    ",
    description:
      "As it is not built for the working conditions and pressures inside your compressor, it may be subject to faster wear and shorter service life. Excessive flow resistance may cause pressure drops, resultin...      ",
    logo: OriginalImage,
  },
];
export default function OurServices() {
  console.log(services);
  return (
    <div
      className="bg-[#F6F8F9] xl:-mt-[150px] 3xl:-mt-[250px] 2xl:-mt-[9%] w-full max-w-full overflow-hidden"
      id="SERVICES"
    >
      <div className="relative z-50">
        <div className="relative flex flex-wrap md:flex-nowrap gap-10  md:mt-[50px] md:translate-x-[18%] lg:translate-x-[25%] xl:translate-x-0 xl:left-[calc(50%+58px)] 2xl:gap-14 2xl:absolute 2xl:right-[calc(60%-216px-650px)] 2xl:left-auto  3xl:right-auto 3xl:left-[52%] 3xl:relative">
          <div className="w-[130px] md:w-36 2xl:w-[200px] 3xl:w-72 3xl:h-20 absolute top-12 left-4 md:relative md:top-0 md:left-0">
            <ElangLogo />
          </div>
          <div className="w-[130px] md:w-36  3xl:w-56 absolute top-24 left-1/2 transform -translate-x-1/2 md:relative md:top-0 md:left-0 md:transform-none">
            <ApuredaLogo />
          </div>
          <div className="w-[120px] md:w-36 3xl:w-56 absolute top-36 right-12 md:relative md:top-0 md:right-0 border-0 m-0 p-0 shadow-none">
            {/* <SunwnLogo /> */}
            <img src={Sun1} width={155} height={40} />
          </div>
        </div>
      </div>

      <div className="pt-60 md:-mt-28 2xl:mt-12 3xl:w-full xl:pb-[40px] mx-container">
        <p className="text-[#E73338] font-bold text-xl 3xl:text-2xl ">
          Our Services
        </p>
        <p className="text-3xl md:text-3xl  font-extrabold mt-2 3xl:text-4xl 3xl:leading-[3rem]">
          A comprehensive <br /> set of services
        </p>
        <div className="mt-10 ">
          {services.map(({ id, title, description, logo }, index) => {
            return (
              <div key={id}>
                <div className="hover:bg-transparent group relative pb-5 max-w-full ">
                  <div className="flex items-center justify-between">
                    <div className="mr-10 flex">
                      <img
                        className="w-[30px] h-[30px] mt-3 2xl:mt-10 lg:mt-[40px] md:mt-10"
                        src={logo}
                        alt="Original"
                      />
                      <p className="font-bold md:text-2xl text-[12px] ml-5 mt-3 md:mt-10 lg:mt-0 2xl:pt-10 lg:pt-[40px] 3xl:text-3xl">
                        {title}
                      </p>
                    </div>
                    <div className="pr-4 md:pr-0 md:mt-2 mb-6 md:mb-0 lg:pr-0 xl:pr-0  2xl:mt-5">
                      <PathArrow />
                    </div>
                  </div>
                  <p className="ml-[50px] md:mb-5 text-[#9D9D9D] w-[80%] md:w-[580px] lg:w-[700px] xl:w-[1050px] text-xs md:text-base 3xl:text-2xl xl:mt-3  2xl:w-[90%] xl:pb-3 xl:text-[18px] md:mt-[15px] line-clamp-3">
                    {description}
                  </p>
                </div>
                {index !== 4 && (
                  <p className="border-b border-[#C8C8C8] absolute left-0 mb-5 w-[96%] sm:w-[calc(100%-((100%-640px)/2)-40px)] md:w-[calc(100%-((100%-768px)/2)-40px)] lg:w-[calc(100%-((100%-1024px)/2)-40px)] xl:w-[calc(100%-((100%-1280px)/2)-56px)] 2xl:w-[calc(100%-((100%-1536px)/2)-64px)] 4xl:w-[calc(100%-((100%-1920px)/2)-64px)] 3xl:w-[calc(100%-((100%-2160px)/2)-80px)] "></p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
