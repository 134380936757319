import React from "react";

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 22 15"
      className="xl:w-[22px] xl:h-[14px]"
    >
      <path
        fill="#fff"
        d="M21.656 6.7L15.533.829a1.203 1.203 0 00-1.657.004 1.1 1.1 0 00.004 1.596l4.11 3.942H1.171C.525 6.371 0 6.876 0 7.5s.524 1.129 1.171 1.129h16.82l-4.11 3.942a1.1 1.1 0 00-.004 1.596 1.203 1.203 0 001.657.004l6.121-5.87.001-.002a1.101 1.101 0 000-1.598z"
      ></path>
    </svg>
  );
}

export default Arrow;
