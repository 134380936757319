import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import HOME_PAGE from "./components/Home";
import AboutUs from "./components/images/About";
import Products from "./components/OurProducts";
import Carousel from "./components/ProductCarousal";
import ProductsData from "./components/ProductsData";
import ContactUs from "./components/ContactUs";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import OurServices from "./components/OurServices";

function App() {
  return (
    <>
      <Navbar />
      <HOME_PAGE />
      <AboutUs />
      <OurServices />
      <Products />
      <Carousel ProductsData={ProductsData} />
      <ContactUs />
      <Gallery />
      <Footer />
    </>
  );
}

export default App;
