import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className="3xl:w-8 3xl:h-8"
    >
      <path
        fill="#E73338"
        d="M13.184 5.888c.213 0 .32.107.32.32v1.344c0 .213-.107.32-.32.32H7.872c-.085 0-.128.043-.128.128v5.184c0 .213-.107.32-.32.32H6.08c-.213 0-.32-.107-.32-.32V8c0-.085-.043-.128-.128-.128H.32c-.213 0-.32-.107-.32-.32V6.208c0-.213.107-.32.32-.32h5.312c.085 0 .128-.043.128-.128V.32c0-.213.107-.32.32-.32h1.344c.213 0 .32.107.32.32v5.44c0 .085.043.128.128.128h5.312z"
      ></path>
    </svg>
  );
}

export default PlusIcon;
