import OilFreeImage from "./images/Products/OilFreeImage";

const ProductsData = [
  {
    id: 1,
    description: "Oil Free Scroll Compressor",
    image: require("./images/Products/oil-free_scroll_air_compressor 1.png"),
  },
  {
    id: 2,
    description: "Variable Speed Drive Com...",
    image: require("./images/Products/variable-speed-drive-compressor 1.png"),
  },
  {
    id: 3,
    description: "Oil Free Scroll Compressor",
    image: require("./images/Products/oil-free_scroll_air_compressor 1.png"),
  },
  {
    id: 4,
    description: "Oil Free Scroll Compressor",
    image: require("./images/Products/variable-speed-drive-compressor 1.png"),
  },
  {
    id: 5,
    description: "Oil Free Scroll Compressor",
    image: require("./images/Products/OilFree.png"),
  },
  {
    id: 6,
    description: "Variable Speed Drive Com...",
    image: require("./images/Products/CVS.png"),
  },
  {
    id: 7,
    description: "Oil Free Scroll Compressor",
    image: require("./images/Products/OilFree.png"),
  },
  {
    id: 8,
    description: "Variable Speed Drive Com...",
    image: require("./images/Products/CVS.png"),
  },
];
export default ProductsData;
