import Arrow from "./Icons/Arrow";
import chatIcon from "./images/ic/ChatIcon.svg";
import locIcon from "./images/ic/LocIcon.svg";
import { useEffect, useState } from "react";
export default function ContactUs() {
  const initialValues = {
    userFullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  };
  const handleChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    const nameRegex = /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex =
      /^\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    if (!values.userFullName) {
      errors.userFullName = "Username is required";
    } else if (!nameRegex.test(values.userFullName)) {
      errors.userFullName = "This is not a valid Full name";
    }
    if (!values.email) {
      errors.email = "email is required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "phoneNumber is required";
    } else if (!phoneRegex.test(values.phoneNumber)) {
      errors.phoneNumber = "This is not a valid phone number";
    }
    if (!values.subject) {
      errors.subject = "subject is required";
    }
    // } else if (!nameRegex.test(values.subject)) {
    //   errors.subject = "This is not a valid Subject";
    // }
    if (!values.message) {
      errors.message = "message is required";
    }
    // else if (!nameRegex.test(values.message)) {
    //   errors.message = "This is not a valid message";
    // }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    // setFormValues({ ...formValues, initialValues });
  };
  useEffect(() => {
    console.log(formErrors);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
      setFormValues(initialValues);
      setIsSubmit(false);
    }
  }, [formErrors]);
  return (
    <div
      id="Contact-us"
      className="border-solid  w-[auto] md:h-full bg-black text-white flex flex-col-reverse md:items-end justify-end relative lg:right-0 "
    >
      <div className=" text-white bg-[#F6F8F9] md:w-[45%] lg:w-[45%] md:h-full md:py-20 2xl:px-20 xl:px-16 lg:px-12 md:absolute -bottom-20 py-10 px-7 md:p-0 left-0 xl:w-2/4 ">
        <div>
          <form
            action=""
            className="text-black p-3 md:p-10 lg:p-0 md:space-y-3 xl:pl-20 2xl:pl-0 "
          >
            <div className="grid grid-cols-2 gap-4 3xl:text-2xl 2xl:text-xl ">
              <div className="flex flex-col">
                <label htmlFor="full-name">Full name</label>
                <input
                  id="userFullName"
                  name="userFullName"
                  className="h-10 border-b border-[#C8C8C8] bg-transparent text-gray-900 focus:outline-none focus:border-rose-600 text-xs md:text-sm 3xl:text-xl 2xl:text-lg mb-5"
                  type="text"
                  placeholder="Your full name"
                  value={formValues.userFullName}
                  onChange={handleChange}
                />
                <p className="text-red-400">{formErrors.userFullName}</p>
              </div>
              <div className="flex flex-col 3xl:text-2xl 2xl:text-xl">
                <label htmlFor="email">Email address</label>
                <input
                  id="email"
                  name="email"
                  className="h-10 border-b border-[#C8C8C8] bg-transparent  text-gray-900 focus:outline-none focus:border-rose-600 text-xs md:text-sm 3xl:text-xl 2xl:text-lg"
                  type="email"
                  placeholder="Your email address"
                  value={formValues.email}
                  onChange={handleChange}
                />
                <p className="text-red-400">{formErrors.email}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col 3xl:text-2xl 2xl:text-xl 2xl:mt-5">
                <label htmlFor="full-name">Phone number</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  className="h-10 border-b border-[#C8C8C8] bg-transparent text-gray-900 focus:outline-none focus:border-[#E73338] text-xs md:text-sm 3xl:text-xl 2xl:text-lg mb-5"
                  type="text"
                  placeholder="Your Phone number"
                  value={formValues.phoneNumber}
                  onChange={handleChange}
                />
                <p className="text-red-400">{formErrors.phoneNumber}</p>
              </div>
              <div className="flex flex-col 3xl:text-2xl 2xl:text-xl 2xl:mt-5">
                <label htmlFor="Subject">Subject</label>
                <input
                  id="subject"
                  name="subject"
                  className="h-10 border-b border-[#C8C8C8] bg-transparent text-gray-900 focus:outline-none focus:border-rose-600 text-xs md:text-sm 3xl:text-xl 2xl:text-lg"
                  type="subject"
                  placeholder="ex. Air Testing"
                  value={formValues.subject}
                  onChange={handleChange}
                />
                <p className="text-red-400">{formErrors.subject}</p>
              </div>
            </div>

            <div className="flex flex-col mt-4 3xl:text-2xl 2xl:text-xl">
              <label htmlFor="message" className="2xl:mt-5">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="h-8  border-b border-[#C8C8C8] bg-transparent text-gray-900 focus:outline-none focus:border-rose-600 resize-none text-xs md:text-sm 3xl:text-xl 3xl:mt-3 2xl:text-lg "
                placeholder="Message here"
                value={formValues.message}
                onChange={handleChange}
              ></textarea>
              <p className="text-red-400">{formErrors.message}</p>
            </div>
          </form>
          <button
            onClick={handleSubmit}
            className="bg-[#E73338] text-white rounded-full px-6 py-3 pl-30 mt-5 md:mt-0
          
          lg:mt-20 text-xs md:ml-10 lg:ml-0 xl:ml-20 2xl:ml-0 3xl:text-xl 3xl:px-9 3xl:py-3 xl:text-base flex items-center gap-3"
          >
            SEND MESSAGE
            <span className=" ">
              <Arrow />
            </span>
          </button>
        </div>
      </div>
      <div className="space-y-10 p-10 lg:pl-0 md:w-1/2 md:mt-8 lg:w-1/2  2xl:pl-20 md:text-xs xl:pl-24  ">
        <div className="space-y-5 ">
          <p className="text-3xl lg:text-4xl font-bold md:text-3xl xl:text-5xl 2xl:text-6xl 3xl:leading-[60px] ">
            Contact us to <br /> request a quote
          </p>
          <p className="font-light 2xl:font-light 2xl:text-xl text-base">
            For quick inquiry please submit below form.
          </p>
        </div>

        <div className="space-y-10 2xl:font-light 3xl:text-xl 2xl:text-lg ">
          <div className="space-y-4">
            <p className="flex items-center ">
              <img src={chatIcon} alt="logo1" />
              <span className="ml-3 text-[20px] ">Contact Us</span>
            </p>
            <div className="space-y-2 text-base">
              <div className="flex flex-wrap gap-x-1">
                <p>Phone:</p>
                <p>042-35411331</p>
                <p>/</p>
                <p>0300-8463763</p>
              </div>
              <p className="max-w-[200px] sm:max-w-full"></p>
              <p>Email: info@shaffaf.org</p>
              <p>Fax: 041-35411331</p>
            </div>
          </div>
          <div className="space-y-4">
            <p className="flex items-center">
              <img src={locIcon} alt="Location" />
              <span className="ml-4 text-[20px]">Our Location</span>
            </p>
            <p className="text-base xl:max-w-[520px] 2xl:text-[18px] 3xl:text-[20px] ">
              Address: Office # 1 2nd floor, Al-Hamd Plaza, 34 civic centre
              Maustafa Town, Wahdat Road, Lahore Pakistan
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
