import React, { useState } from "react";
import { Link } from "react-scroll";
import ShaffafLogo from "./ShaffafLogo";

const MenuNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { name: "HOME", id: 1 },
    { name: "ABOUT US", id: 2 },
    { name: "SERVICES", id: 3 },
    { name: "PRODUCTS", id: 4 },
    { name: "GALLERY", id: 5 },
    { name: "DOWNLOAD", id: 6 },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="z-10 flex items-center justify-between visible md:invisible md:h-0 pl-3 pb-1.5 ">
        <div className="md:pb-20 xl:pl-[110px] xl:-mt-3 ">
          <ShaffafLogo />
        </div>

        <div className="relative z-10 pr-3 ">
          <button
            onBlur={() =>
              setTimeout(() => {
                setIsOpen(false);
              }, 100)
            }
            onClick={toggleMenu}
            className="flex items-center px-3 py-2  border rounded text-gray-200 border-red-400 hover:text-white hover:border-white "
          >
            <svg
              className=" h-3 w-3 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          {isOpen && (
            <div className="bg-gray-800 p-4 right-9 absolute z-50">
              <div className="flex flex-col">
                {menuItems.map((menuItem) => (
                  <Link
                    to={menuItem.name}
                    smooth={true}
                    duration={500}
                    key={menuItem.id}
                    href="#"
                    onClick={toggleMenu}
                    className="text-white hover:bg-gray-700 px-4 py-2 "
                  >
                    {menuItem.name}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MenuNavBar;
