import React from "react";

function PathArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="14"
      fill="none"
      viewBox="0 0 22 14"
      className=" opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-5 2xl:mt-0 w-3 md:w-5"
    >
      <path
        fill="#E73338"
        d="M21.656 6.2L15.533.329a1.203 1.203 0 00-1.657.004 1.1 1.1 0 00.004 1.596l4.11 3.942H1.171C.525 5.871 0 6.376 0 7s.524 1.129 1.171 1.129h16.82l-4.11 3.942a1.1 1.1 0 00-.004 1.596 1.203 1.203 0 001.657.004l6.121-5.87.001-.002a1.101 1.101 0 000-1.598z"
      ></path>
    </svg>
  );
}

export default PathArrow;
