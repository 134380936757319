import React, { useState } from "react";
// import "./Carousel.css"; // Import CSS file for carousel styling
import ReadMore from "./ShowDescription";
import CarouselArrow from "./Icons/CarousalArrow";
const Carousel = ({ ProductsData }) => {
  return (
    <div>
      <div className="flex lg:relative max-w-[calc(100%-1.9rem)] 2xl:pl-0 overflow-auto ml-auto   md:max-w-[calc(100%-((100%-768px)/2)-40px)] lg:max-w-[calc(100%-((100%-1024px)/2)-40px)] xl:max-w-[calc(100%-((100%-1280px)/2)-56px)] 2xl:max-w-[calc(100%-((100%-1536px)/2)-64px)] 4xl:max-w-[calc(100%-((100%-1920px)/2)-64px)] 3xl:max-w-[calc(100%-((100%-2160px)/2)-80px)] md:flex-nowrap gap-x-2 container no-scrollbar">
        {ProductsData?.map((product, index) => (
          <div className="mb-10 md:mb-0" key={index}>
            <div className="mt-10 ">
              <img
                width={345}
                height={258}
                src={product.image}
                alt={product.description}
                className="md:max-w-60 md:h-60 max-w-[206px] h-[12rem] xl:w-[345px] xl:h-[258px] 2xl:min-w-60 2xl:h-[200px] 3xl:min-w-96 3xl:h-[350px] border bg-[#F6F8F9] border-b-0 "
              />
            </div>

            <div className="text-start p-3 space-y-2 md:mb-36 flex-wrap border bg-[#F6F8F9] border-t-0 ">
              <p className="font-bold text-sm xl:text-[16px] 2xl:text-sm 3xl:text-2xl xl:mb-[6px] line-clamp-1">
                {product.description}
              </p>

              {/* <ReadMore ProductsData={ProductsData.description} /> */}
              <div className="group">
                <button className="group-hover:text-[#E73338] text-[#5F5C5F] text-[13px] xl:text-sm 3xl:text-xl 2xl:text-[13px] flex items-center">
                  READ MORE
                  <span className="ml-2 ">
                    <CarouselArrow />
                  </span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
