import Linkedin from "./Icons/LinkedIn";
import FacebookIcon from "./Icons/Facebook";
import InstaGram from "./Icons/Instagaram";
export default function MdFooter({ className }) {
  return (
    <div className={className}>
      <div className="lg:pl-14 xl:pl-0">
        <div className="flex items-end gap-8">
          <div>
            <Linkedin />
          </div>
          <div>
            <FacebookIcon />
          </div>
          <div>
            <InstaGram />
          </div>
        </div>
        <div className="">
          <div className="md:flex items-start gap-4 mr-10 mt-4 mb-10 md:mb-0 flex-nowrap lg:block xl:flex 3xl:flex-nowrap 3xl:text-lg 3xl:pr-0">
            <input
              className=" border bg-transparent font-light rounded-full pl-3 pt-2 pb-2 outline-none min-w-56 xl:min-w-64 3xl:min-w-80 "
              type="email"
              placeholder="Enter your email"
            />
            <button className="border-[#E73338] bg-[#E73338] text-white py-2 md:px-5 px-10 mt-3 md:mt-0 rounded-full 3xl:text-lg lg:mt-3 xl:mt-0">
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
