import React from "react";

function CarouselArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      fill="none"
      viewBox="0 0 18 12"
      className="group-hover:text-[#E73338]"
    >
      <path
        fill="currentColor"
        d="M17.719 5.315h-.001L12.71.281a.952.952 0 00-1.356.003.974.974 0 00.003 1.369l3.363 3.378H.959A.963.963 0 000 6c0 .534.43.968.959.968h13.76l-3.362 3.378a.974.974 0 00-.003 1.369c.374.379.98.38 1.356.003l5.008-5.032v-.001a.975.975 0 000-1.37z"
      ></path>
    </svg>
  );
}

export default CarouselArrow;
