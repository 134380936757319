import ShaffafLogo from "./images/ShaffafLogo";
import MdFooter from "./Md:Footer";
export default function Footer() {
  return (
    <div className="bg-[#0E181E] text-white pt-4 md:pt-0 overflow-x-hidden">
      <div className="md:flex md:pt-20 lg:px-20 3xl:pr-0 md:pl-20 max-w-[90%] w-full pl-5  pt-4">
        <div className="md:w-1/2 xl:w-[40%] 2xl:w-[40%] 3xl:w-1/2 px-3 md:px-0 3xl:pl-10">
          {/* <img src={Logo} alt="" /> */}
          <ShaffafLogo />
          <p className="text-xs font-light  md:w-9/12 xl:w-[360px] xl:leading-[1.3rem] 2xl:w-9/12 pt-6 3xl:text-2xl 3xl:w-2/3 2xl:text-sm ">
            At Ceasta Assurance, we are passionate about insuring the finer
            things in life that you care so much about. We strive to provide
            innovative and comprehensive insurance solutions so these
            investments will be covered in case of accidents. Our mission is to
            become the trusted partner of our clients, offering peace of mind
            through our expert knowledge, exceptional service, and commitment to
            their success.
          </p>
        </div>
        <div className="flex flex-row lg:flex gap-[60px] md:w-1/3 lg:w-1/3 mt-10 md:mt-0 px-3 md:px-0  3xl:text-lg 3xl:gap-24">
          <div className="w-1/3 xl:w-1/2 space-y-1 font-light text-[14px] xl:text-lg ">
            <p className=" mb-5 3xl:text-2xl font-medium">Information</p>
            <div className="md:text-xs lg:text-sm 2xl:text-sm space-y-3 md:space-y-[15px] lg:space-y-[15px] 3xl:text-2xl xl:space-y-3 2xl:space-y-3 3xl:space-y-[19px]">
              <p>About Us</p>
              <p>Products</p>
              <p>Contact Us</p>
              <p>Services</p>
              <p>Gallery</p>
            </div>
          </div>
          <div className="space-y-3 w-2/3 text-[14px] font-light  ">
            <p className=" mb-[19px] font-medium xl:text-[18px] 3xl:text-2xl">
              Contact
            </p>
            <div className="space-y-4 md:text-xs lg:text-sm xl:text-sm xl:leading-[16.41px] 3xl:text-2xl ">
              <p className="md:text-xs lg:text-sm md:w-[200px] md:leading-[1rem] lg:w-[200px] xl:w-[190px] 3xl:w-[350px] text-[14px] 3xl:text-2xl ">
                Address: Office # 1 2nd floor, Al-Hamd Plaza, Wahdat Road,
                Lahore Pakistan
              </p>
              <p>310-295-2279</p>
              <p>info@shaffaf.org</p>
              <p>Privacy & Cookies</p>
              <p>Terms and Conditions</p>
            </div>
          </div>
        </div>
        <MdFooter
          className={
            " w-0 lg:w-1/3 xl:w-1/6 pl-16 invisible lg:visible justify-self-end"
          }
        />
      </div>
      <MdFooter
        className={
          "pl-8 md:pl-20 md:mb-10 lg:md-0 px-3 md:px-0 md:pt-10 visible lg:invisible lg:h-0 -mt-12 md:-mt-0"
        }
      />
      <div>
        <div className="flex md:flex md:items-center md:justify-between border-t-2 border-gray-400 mx-3 md:mx-20 pb-10 gap-5   font-light text-xs">
          <p className="mt-5 3xl:text-lg pl-5 md:pl-0">
            ©Copyright 2024 www.shaffaf.org All rights reserved.
          </p>
          <p className="mt-5  3xl:text-lg md:pt-0  ">
            Producer License #4267131
          </p>
        </div>
      </div>
    </div>
  );
}
