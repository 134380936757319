import React from "react";

function InstaGram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        fill="#fff"
        d="M18.5 11.868c-3.226 0-5.858 2.632-5.858 5.858 0 3.225 2.632 5.858 5.858 5.858 3.226 0 5.858-2.633 5.858-5.858 0-3.226-2.632-5.858-5.858-5.858zm17.57 5.858c0-2.426.021-4.83-.115-7.251-.136-2.813-.778-5.309-2.834-7.366C31.06 1.05 28.568.411 25.755.275c-2.425-.136-4.83-.114-7.25-.114-2.426 0-4.83-.022-7.252.114-2.812.136-5.308.778-7.365 2.834-2.06 2.061-2.698 4.553-2.834 7.366-.137 2.425-.115 4.83-.115 7.25 0 2.422-.022 4.83.115 7.252.136 2.812.778 5.308 2.834 7.365 2.061 2.06 4.553 2.698 7.365 2.834 2.426.137 4.83.115 7.251.115 2.426 0 4.83.022 7.251-.115 2.813-.136 5.309-.778 7.366-2.834 2.06-2.061 2.698-4.553 2.834-7.365.14-2.422.114-4.826.114-7.251zM18.5 26.739a9 9 0 01-9.013-9.013A9 9 0 0118.5 8.712a9 9 0 019.013 9.014 9 9 0 01-9.013 9.013zm9.382-16.29a2.102 2.102 0 01-2.105-2.106c0-1.164.94-2.105 2.105-2.105a2.102 2.102 0 011.946 2.911 2.102 2.102 0 01-1.946 1.3z"
      ></path>
    </svg>
  );
}

export default InstaGram;
