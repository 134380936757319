import compressor from "./Compressor.png";
import compressor2 from "./AboutUsCompressor.png";

export default function AboutUs() {
  return (
    <>
      <div className="flex flex-col-reverse md:flex-row items-start justify-start py-20 lg:gap-28 md:space-y-0 md:mt-32 mx-container ">
        <div className="md:basis-3/5">
          <p
            id="ABOUT US"
            className="text-[#5F5C5F] text-xs md:text-base xl:text-base md:w-11/12 lg:w-[395px] xl:ml-0 pt-3 md:pt-0 lg:pl-0  3xl:text-xl 3xl:w-[850px] xl:w-[490px] xl:leading-[20px] "
          >
            Shaffaf was established in 2005 in Pakistan. They have staff those
            who have more than 40 years’ experience and specialist on compressor
            Parts. They provide services for maintenance of industrial air
            compressors, generators, chillers and other industrial equipment.
            <br /> <br />
            As one of the Pakistan’s largest air compressor supplier, Shaffaf
            products have won quality improvement, science and technology and
            awards, and a number of product design patents
          </p>
        </div>
        <div className="md:basis-3/5 space-y-5 md:pl-9 lg:pl-0  2xl:pl-0  ">
          <p className="text-[#E73338] font-bold 3xl:text-xl">
            Welcome To Our Company
          </p>
          <div>
            <p className="text-black md:text-4xl text-3xl font-bold lg:text-[40px] lg:leading-[57px] lg:w-[314px] 2xl:w-[550px] 2xl:text-6xl 3xl:w-[570px] 3xl:text-7xl ">
              We're the future <p className="2xl:my-3">of metallurgy</p>
              <p className="3xl:my-3">industry</p>
            </p>
          </div>
          <div>
            <button className="border 1 solid py-3 px-12 mb-16 mt-[40px] md:md-0 md:mt-0 xl:mt-10  text-black border-black font-bold text-base  rounded-full">
              SEE MORE
            </button>
          </div>
        </div>
      </div>
      <img
        className="w-full xl:w-[calc(50%-97px)] lg:-mt-[50px] xl:-mt-[220px]"
        src={compressor2}
        alt="Compressor"
      />
    </>
  );
}
